<template>
<div>
  <div class="basesetting">

    <div class="baseitem" >
      <div class="baseleft">标题：</div>
      <el-input class="basemid" v-model="video.title"></el-input>
    </div>

    <div class="baseitem">
      <div class="baseleft">封面：</div>
      <div>

        <div style="width: 360px;height: 270px;margin: 15px 0;">
          <img :src="video.cover" style="object-fit: cover;width: 100%;height: 100%;"/>
        </div>
        <el-upload
            class="upload-demo"
            :file-list="file_list"
            :limit="1"
            action="#"
            :auto-upload="false"
            :on-change="handleChange">
          <el-button slot="trigger" v-if="video.cover" style="margin-right: 5px" size="small" type="primary">更换封面</el-button>
          <el-button slot="trigger" v-else style="margin-right: 5px" size="small" type="primary">上传封面</el-button>
          <el-button size="small" v-if="!isDelCover" @click="isDelCover = true">删除封面</el-button>
          <el-button size="small" v-if="isDelCover" @click="isDelCover = false">取消删除</el-button>
          <div slot="tip" class="el-upload__tip">只能上传jpg/png文件，且不超过500kb</div>
        </el-upload>
      </div>

      <div class="baseleft" style="margin-left: 70px;">权重：</div>
      <el-input style="max-width: 220px;" v-model="video.weight"></el-input>
    </div>
    <div class="baseitem" style="margin-top: 40px;">
      <div class="baseleft">视频：</div>
      <div style="margin-left: 98px;">
        <el-upload
            class="upload-demo"
            :file-list="video_list"
            :limit="1"
            action="#"
            :auto-upload="false"
            :on-change="handleVideoChange">
          <el-button size="small" type="primary">点击上传</el-button>

        </el-upload>
      </div>
    </div>
    <div>
      <el-button @click="save" type="primary" style="margin-right: 100px;margin-top: 50px;">保存</el-button>
      <el-button @click="setAdminState('videoList'); setTabsValue('videoList')">取消</el-button>
    </div>
  </div>
</div>
</template>

<script>
import {mapMutations, mapState} from "vuex";

export default {
  name: "VideoEdit",
  props:{
    edit:{
      type:Boolean,
      default:false,
    }
  },
  data(){
    return{
      video:{
        id:1,
        title:'',
        weight:0,
        cover:''
      },
      file_list: [],
      video_list: [],
      isDelCover:false,
    }
  },
  computed:{
    ...mapState([
        'video_editing'
    ]),
  },
  methods:{
    ...mapMutations([
        'setVideoEditing',
        'setTabsValue',
        'setAdminState',
        'updateVideoList'
    ]),
    save(){
      console.log('file', this.file_list);

      const formData = new FormData()
      formData.append('title', this.video.title);
      formData.append('weight',this.video.weight);


      if (this.video_list.length > 0) {
        formData.append('video', this.video_list[0].raw);
      }

      if (this.isDelCover){
        // 删除封面
        formData.append('cover', '');
      } else if (this.file_list.length > 0) {
        formData.append('cover', this.file_list[0].raw);
      }
      console.log(this.doc)
      console.log(this.file_list)

      if (this.edit) {
        this.axios({
          url: '/api/video/admin/edit/'+this.video.videoId+'/',
          method: "put",
          headers: {
            "Content-Type": "multipart/form-data",
          },
          data: formData,
        }).then((res) => {
          console.log("res->:", res);
          this.updateVideoList();
          this.setAdminState('videoList');
          this.setTabsValue('videoList');
        }).catch(err=>{
          console.log(err,'dcy')
        });
      } else {
        this.axios({
          url: '/api/video/admin/list/',
          method: "post",
          headers: {
            "Content-Type": "multipart/form-data",
          },
          data: formData,
        }).then((res) => {
          console.log("res->:", res);
          this.updateVideoList();
          this.setAdminState('videoList');
          this.setTabsValue('videoList');
        }).catch(err=>{
          console.log(err,'dcy')
        });
      }
    },
    handleChange(file, fileList){
      // console.log('bug', this.doc_cover);
      // this.doc_cover = file.raw;
      this.file_list = fileList;
      // console.log('bug', this.doc_cover);
      console.log('fl', this.file_list);
      // this.file_list.push(file.raw);
    },
    handleVideoChange(file, fileList){
      // console.log('bug', this.doc_cover);
      // this.doc_cover = file.raw;
      this.video_list = fileList;
      // console.log('bug', this.doc_cover);
      console.log('vl', this.video_list);
      // this.file_list.push(file.raw);
    },
  },
  mounted() {
    if(this.edit){ //
      this.axios.get(
          '/api/video/admin/edit/'+this.video_editing.videoId+'/'
      ).then(res=>{
        this.video = res.data
      })
    }
  }
}
</script>

<style scoped>
.basesetting{
  /*border: solid 1px red;*/
  max-width: 800px;
  margin: 10px auto;
}
.baseitem{
  margin-bottom: 15px;
  display: flex;
}
.baseleft{
  text-align: right;
  min-width: 50px;
  margin-top: 8px;
  margin-right: 5px;
}
.basemid{
  max-width: 705px;
}
.baseright{
  min-width: 100px;
  text-align: left;
  margin-top: 8px;
  padding-left: 10px;
  color: #aaaaaa;
}
</style>